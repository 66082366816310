import { DryingMethodService } from 'services/drying-method-service';
import { ImpregnationMethodService } from 'services/impregnation-method-service';
import { I18N } from 'aurelia-i18n';
import { TranslationService } from './../../services/translation-service';
import { ImpregnationTestService } from './../../services/impregnation-test-service';
import { ServiceStationService } from 'services/service-station-service';
import { MathService } from './../../services/math-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ImpregnationService } from 'services/impregnation-service';
import { ImpregnationTypeService } from 'services/impregnation-type-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { Logger } from 'aurelia-logging';
import { ImpregnatedChoice } from 'models/Impregnated';

const logger: Logger = LogManager.getLogger('form-impregnation');

@autoinject
export class FormImpregnation {
  @bindable
  private netId: number;
  private net: Models.Net;
  private impregnation: Models.Impregnation;
  private originalObject: Models.Impregnation;
  private nextTabIndex: number = null;
  private subscription: Subscription;
  private resetImpregnationTestListSubscription: Subscription;

  private impregnationTests: Array<Models.ImpregnationTest>;

  private _impregnationAmountFactor: number;
  private testEditFormVisible: boolean = false;

  private isOfferContext: boolean = false;
  private isNetContext: boolean = false;

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private impregnationService: ImpregnationService,
    private impregnationTypeService: ImpregnationTypeService,
    private impregnationMethodService: ImpregnationMethodService,
    private dryingMethodService: DryingMethodService,
    private impregnationTestService: ImpregnationTestService,
    private serviceStationService: ServiceStationService,
    private translationService: TranslationService,
    private i18n: I18N,
    private dialogService: DialogService,
    private mathService: MathService,
    private utility: Utility
  ) {}

  private async activate(params) {
    this.netId = params.NetId || params.Id;

    await this.getNet(this.netId);

    this.getImpregnationTest(this.netId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    // refresh if locked / unlocked
    this.subscription = this.eventAggregator.subscribe('netLocked', (value) => {
      this.getNet(this.netId);
    });

    this.resetImpregnationTestListSubscription = this.eventAggregator.subscribe(
      'impregnationTestListReset',
      (value) => {
        this.getImpregnationTest(this.netId);
        return;
      }
    );
  }

  private created() {
    if (
      this.router &&
      this.router.currentInstruction &&
      this.router.currentInstruction.config.name === 'offer-detail-impregnation'
    ) {
      this.isOfferContext = true;
    }

    if (
      this.router &&
      this.router.currentInstruction &&
      this.router.currentInstruction.config.name === 'net-detail-impregnation'
    ) {
      this.isNetContext = true;
    }

    logger.debug('this.router.currentInstruction', this.router.currentInstruction);
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }

    if (this.resetImpregnationTestListSubscription) {
      this.resetImpregnationTestListSubscription.dispose();
    }
  }

  // Get net
  private getNet(id) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;
        if (!this.net.ImpregnationId) return;
        this.getImpregnation(this.net.ImpregnationId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  // Get impregnation
  private getImpregnation(id) {
    this.impregnationService
      .get(id)
      .then((res) => {
        this.impregnation = res;
        this.updateOriginalObject();
        this.calculateImpregnationAmount(this.netId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateOriginalObject() {
    if (!this.impregnation) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.impregnation));
  }

  private calculateImpregnationAmount(netId: number) {
    this.impregnationService
      .calculateImpregnationAmount(netId)
      .then((res) => {
        if (res) {
          if (res.CalculatedAmount && this.net.IsMorenotNet) {
            this.impregnation.ImpregnationAmount = res.CalculatedAmount;
          }

          if (res.CalculatedAmount && res.CalculatedNettingWeight) {
            this._impregnationAmountFactor = this.mathService.round(res.CalculatedFactor, 1);
          } else {
            this._impregnationAmountFactor = null;
          }
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async updateImpregnation() {
    try {
      if (!this.impregnation?.Id) {
        this.net.Impregnation = {
          Id: null,
          ImpregnationTypeId: this.impregnation.ImpregnationTypeId,
          ImpregnationAmount: this.impregnation.ImpregnationAmount,
        } as Models.Impregnation;
        await this.saveNet();
        return;
      }

      await this.impregnationService.put(this.impregnation, this.impregnation.Id);
      this.toastService.showSuccess('impregnation.updated');
      this.updateOriginalObject();
      this.calculateImpregnationAmount(this.netId);
      await this.saveNet();
      this.eventAggregator.publish('offer-refresh', 'price');
      this.eventAggregator.publish('offer-refresh', 'impregnation');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getImpregnationTest(id) {
    this.impregnationTestService
      .getAll('?$filter=NetId eq ' + id + '&$expand=ImpregnationTestType&$orderby=Id')
      .then((res) => {
        switch (this.i18n.getLocale()) {
          case 'no':
          case 'nb-NO':
          case 'nb':
            this.impregnationTests = res.sort((a, b) =>
              a.ImpregnationTestType.Name > b.ImpregnationTestType.Name ? 1 : -1
            );
            break;
          case 'es':
            this.impregnationTests = res.sort((a, b) =>
              a.ImpregnationTestType.NameEs > b.ImpregnationTestType.NameEs ? 1 : -1
            );
            break;
          default:
            this.impregnationTests = res.sort((a, b) =>
              a.ImpregnationTestType.NameEn > b.ImpregnationTestType.NameEn ? 1 : -1
            );
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private editLabel(test) {
    let label = this.i18n.tr('general.edit') + ' ';

    label += this.translationService.getLocalizedValue(test.ImpregnationTestType, 'Name');

    return label;
  }

  private saveTest(test) {
    delete test.ImpregnationTestType;
    this.impregnationTestService
      .put(test, test.Id)
      .then((res) => {
        this.toastService.showSuccess('impregnation.impregnationTestSaved');
        this.eventAggregator.publish('impregnationTestListReset', 0);

        this.testEditFormVisible = null;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEditTest() {
    this.testEditFormVisible = null;
  }

  private canDeactivate() {
    if (!this.net?.Locked && this.originalObject && !this.utility.areEqual(this.impregnation, this.originalObject)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.updateImpregnation();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  protected async getNettingTypes() {
    const res = await this.impregnationTypeService.getAllCached();

    if (!this.net?.IsMorenotNet) {
      for (const m of res) {
        m.Inactive = false;
      }
    }

    return res.filter((x) => x.NavisionProductId || x.DeltaProductId);
  }

  protected async saveNet() {
    try {
      await this.netService.put(
        {
          Impregnated: this.net.Impregnated,
          Impregnation: this.impregnation.Id
            ? undefined
            : {
                Id: undefined,
                ImpregnationAmount: this.impregnation.ImpregnationAmount,
                ImpregnationTypeId: this.impregnation.ImpregnationTypeId,
              },
        } as Models.Net,
        this.net.Id
      );
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected impregnationChoices() {
    return [
      {
        Name: this.i18n.tr('general.yes'),
        Value: ImpregnatedChoice.Impregnated,
      },
      {
        Name: this.i18n.tr('general.no'),
        Value: ImpregnatedChoice.NotImpregnated,
      },
      {
        Name: this.i18n.tr('general.unknown'),
        Value: ImpregnatedChoice.Unknown,
      },
    ];
  }
}
